import css, { SystemStyleObject } from "@styled-system/css";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { SxProp } from "../constants/sx";
import { clampBuilder } from "../helpers/sizeCalc";
import { FluidImage } from "../types/common/image";
import Box from "./Box/Box";
import MotionBox from "./Box/MotionBox";

export enum ImageSwitchButtonAlign {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}

const ImageSwitchButtonStyleVariants = {
  left: {
    left: ["50%", 0, 0, 0],
    transform: ["translateX(-50%)", "unset", "unset", "unset"],
  },
  right: {
    right: ["50%", 0, 0, 0],
    transform: ["translateX(50%)", "unset", "unset", "unset"],
  },
  center: {
    left: "50%",
    transform: "translateX(-50%)",
  },
};

type Props = {
  images: {
    id: string;
    title: string;
    image: FluidImage;
    imageAlt: string;
  }[];

  direction?: "left" | "right" | "center";
  sx?: SystemStyleObject;
  imageContainerSx?: SystemStyleObject;
  btnContainerSx?: SystemStyleObject;
  btnAlign: ImageSwitchButtonAlign;
};

type ButtonProps = {
  id: number;
  children: React.ReactNode;
  onClick: (src: number) => void;
  isActive: boolean;
};

const StyledText = styled.span<SxProp>`
  color: rgba(187, 171, 145, 1);
  font-size: 21px;
  font-size: ${clampBuilder(599, 1680, 0.75, 1.3125)};
  line-height: 65px;
  font-family: custom_42266;
  font-weight: 400;
  font-feature-settings: "liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  cursor: pointer;

  @media screen and (max-width: 599px) {
    font-size: ${clampBuilder(320, 599, 0.85, 1.5)};
  }
  ${(p) => css(p.sx)};
`;

const Button = ({ children, onClick, id, isActive }: ButtonProps) => {
  const handleClick = React.useCallback(() => {
    onClick(id);
  }, [onClick, id]);
  return (
    <StyledText
      sx={{
        opacity: isActive ? 1 : 0.5,
        cursor: isActive ? "pointer" : "default",
      }}
      onClick={handleClick}
    >
      {children}
    </StyledText>
  );
};

const ImageSwitch = ({
  images,
  direction = "left",
  sx,
  imageContainerSx,
  btnContainerSx,
  btnAlign,
}: Props) => {
  const [activeImg, setActiveImg] = React.useState<number>(0);

  const changeActiveImage = React.useCallback((id: number) => {
    setActiveImg(id);
  }, []);
  return (
    <Box sx={{ width: "100%", height: "100%", position: "relative", ...sx }}>
      <Box
        sx={{
          height: "100%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          ...imageContainerSx,
        }}
      >
        {images.map((image, i) => (
          <MotionBox
            sx={{
              display: "inline-block",
              width: "100%",
              height: "100%",
              transform: `translateX(-${100 * i}%)`,
            }}
            key={image.id}
            animate={{
              opacity: activeImg === i ? 1 : 0,
              transition: {
                duration: 0,
              },
            }}
          >
            <Box
              sx={{
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",

                //   visibility: image.id === activeImg ? "visible" : "hidden",
              }}
              as={GatsbyImage}
              image={image.image.childImageSharp.gatsbyImageData as any}
              alt={image.imageAlt}
            />
          </MotionBox>
        ))}
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: "fit-content",
          ...ImageSwitchButtonStyleVariants[btnAlign],
          "& > :first-child": {
            marginRight: ["14px", "14px", "20px", "20px"],
            
          },
          ...btnContainerSx,
        }}
      >
        {images.map((image, i) => (
          <Button
            isActive={activeImg !== i}
            key={image.id}
            id={i}
            onClick={changeActiveImage}
          >
            {image.title}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default React.memo(ImageSwitch);
