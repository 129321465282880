import css from "@styled-system/css";
import styled from "styled-components";
import { SxProp } from "../../constants/sx";
import { clampBuilder } from "../../helpers/sizeCalc";

export const SectionWrapper = styled("div")<SxProp>`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
  font-family: custom_42264;
  text-align: center;

  @media screen and (max-width: 768px) {
    text-align: left;
  }

  ${(props) => props.sx && css({ ...props.sx })};
`;

export const SectionWrapper2 = styled("div")<SxProp>`
  display: flex;
  flex-direction: column;
  justify-content: "space-between";
  align-items: "center";
  min-height: ${clampBuilder(599, 1680, 14.4375, 31.625)};
  padding: ${clampBuilder(599, 1680, 7, 12.8125)} 0; //205px; //  112px

  & > h1,
  p {
    margin-left: 0//${clampBuilder(599, 1680, 7.1875, 15.94)}; //255px; // 115px
  }

  @media screen and (max-width: 768px) {
    min-height: clampBuilder(320, 599, 14.5625, 29.5625);
    & > h1,
    p {
      margin-left: 0; //255px; // 115px
    }
  }

  ${(props) => props.sx && css({ ...props.sx })};
`;
