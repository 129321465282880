import { useAnimation } from "framer-motion";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { width } from "styled-system";
import useWindowSize from "../hooks/useWindowSize";
import Box from "./Box/Box";
import MotionBox from "./Box/MotionBox";
import GatsbyImageWithArtDirection from "./GatsbyImageWithArtDirection";
import { Title } from "./Styled/Text";

type Props = {
  rightSide: {
    topImage: {
      image: IGatsbyImageData;
      imagemobile: IGatsbyImageData;
      alt: string;
      btn: {
        title: string;
      };
    };
    bottomImage: {
      image: IGatsbyImageData;
      imagemobile: IGatsbyImageData;
      alt: string;
    };
  };
  leftSide: {
    topImage: {
      image: IGatsbyImageData;
      imagemobile: IGatsbyImageData;
      alt: string;
      btn: {
        title: string;
      };
    };
    bottomImage: {
      image: IGatsbyImageData;
      imagemobile: IGatsbyImageData;
      alt: string;
    };
  };
};

const topImage = {
  default: { zIndex: 2 },
  preActive: {
    zIndex: 10,
    transition: {
      duration: 0,
    },
  },
};

const bottomImage = {
  default: { zIndex: 1 },
  preActive: {
    zIndex: 6,
    transition: {
      duration: 0.1,
    },
  },
  activateLeft: {
    x: "100%",
    transition: {
      duration: 0.5,
      ease: "linear",
    },
  },
  activateRight: {
    x: "-100%",
    transition: {
      duration: 0.5,
      ease: "linear",
    },
  },
  dismiss: {
    x: "0%",
    transition: {
      duration: 0.5,
      ease: "linear",
    },
  },
};

const SlidingImages = ({ leftSide, rightSide }: Props) => {
  const [activeSide, setActiveState] = React.useState<"left" | "right" | null>(
    null
  );

  const leftSideAnimationController = useAnimation();
  const rightSideAnimationController = useAnimation();

  const hadleLeftSidePressed = React.useCallback(async () => {
    if (activeSide === null) {
      setActiveState("left");
      await leftSideAnimationController.start("preActive");
      await leftSideAnimationController.start("activateLeft");
    } else if (activeSide !== "right" || activeSide !== null) {
      setActiveState(null);
      await leftSideAnimationController.start("dismiss");
      await leftSideAnimationController.start("default");
    }
  }, [activeSide, leftSideAnimationController]);
  const hadleRightSidePressed = React.useCallback(async () => {
    if (activeSide === null) {
      setActiveState("left");
      await rightSideAnimationController.start("preActive");
      await rightSideAnimationController.start("activateRight");
    } else if (activeSide !== "left" || activeSide !== null) {
      setActiveState(null);
      await rightSideAnimationController.start("dismiss");
      await rightSideAnimationController.start("default");
    }
  }, [activeSide, rightSideAnimationController]);
  const { width } = useWindowSize();
  return (
    <Box
      sx={{
        display: "flex",
        margin: ["40px 0","40px 0","80px 0","80px 0"],
      }}
    >
      <Box
        sx={{
          position: "relative"
          
        }}
      >
        <MotionBox
          sx={{ position: "relative" }}
          initial="default"
          animate={leftSideAnimationController}
          variants={topImage}
        >
          <Title
              
              sx={{
                width: "100%",
                color: "#fff",
                textAlign: "center",
                cursor:   "pointer",
                position: "absolute",
                zIndex: "3",
                bottom: "0",
                left:"0",
                display: ["block", "none", "none", "none"],
              }}
              variant="paragraph"
            >
              {rightSide.topImage.btn.title}
            </Title>
          <GatsbyImageWithArtDirection 
            sx={{
              position: "relative",
              zIndex: "2",
              height: [width, "auto", "auto", "auto"],
              maxHeight: ["500px","100%", "100%", "100%"],
            }}
            as={GatsbyImage}
            desktopImage={rightSide.topImage.image}
            mobileImage={rightSide.topImage.imagemobile}
            alt={rightSide.topImage.alt}
          />

          <MotionBox
            onClick={hadleLeftSidePressed}
            animate={{ opacity: activeSide === "left" ? 1 : 0 }}
            initial={{ opacity: 0 }}
            whileHover={{
              opacity: 1,
              transition: {
                duration: 0.4,
              },
            }}
            sx={{
              background:
                "linear-gradient(0deg, rgba(187, 171, 145, 0.5), rgba(187, 171, 145, 0.5))",
              position: "absolute",
              left: "0",
              top: "0",
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "5",
              cursor:   "pointer"
            }}
          >
            <Title
              
              sx={{
                width: "100%",
                color: "#fff",
                textAlign: "center",
                cursor:   "pointer",
                display: ["none", "block", "block", "block"],
              }}
              variant="paragraph"
            >
              {rightSide.topImage.btn.title}
            </Title>
          </MotionBox>
        </MotionBox>
        <MotionBox
          initial="default"
          variants={bottomImage}
          animate={leftSideAnimationController}
          sx={{ position: "absolute", left: "0", top: "0" }}
        >

          <GatsbyImageWithArtDirection 
            sx={{ width: "100%",
            height: [width, "auto", "auto", "auto"],
            maxHeight: ["500px","100%", "100%", "100%"],
          }}
            as={GatsbyImage}
            alt={rightSide.bottomImage.alt}
            desktopImage={rightSide.bottomImage.image}
            mobileImage={rightSide.bottomImage.imagemobile}
          />
        </MotionBox>
      </Box>

      <Box
        sx={{
          position: "relative",
        }}
      >
        <MotionBox
          initial="default"
          sx={{ position: "relative" }}
          animate={rightSideAnimationController}
          variants={topImage}
        >
          
          <GatsbyImageWithArtDirection
            sx={{
              position: "relative",
              zIndex: "2",
              height: [width, "auto", "auto", "auto"],
              maxHeight: ["500px","100%", "100%", "100%"],
            }}
            as={GatsbyImage}
            desktopImage={leftSide.topImage.image}
            mobileImage={leftSide.topImage.imagemobile}
            alt={leftSide.topImage.alt}
          />
                              <Title
              
              sx={{
                width: "100%",
                color: "#fff",
                textAlign: "center",
                cursor:   "pointer",
                position: "absolute",
                zIndex: "3",
                bottom: "0",
                left:"0",
                display: ["block", "none", "none", "none"],
              }}
              variant="paragraph"
            >
              {leftSide.topImage.btn.title}
            </Title>
          <MotionBox
          onClick={hadleRightSidePressed}
          animate={{ opacity: activeSide === "left" ? 1 : 0 }}
            initial="default"
            whileHover={{
              opacity: 1,
              transition: {
                duration: 0.4,
              },
            }}
            
            sx={{
              background:
                "linear-gradient(0deg, rgba(187, 171, 145, 0.5), rgba(187, 171, 145, 0.5))",
              position: "absolute",
              left: "0",
              top: "0",
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor:   "pointer",
              zIndex: "5",
            }}
          >
            <Title
              
              sx={{
                width: "100%",
                color: "#fff",
                textAlign: "center",
                display: ["none", "block", "block", "block"],
              }}
              variant="paragraph"
            >
              {leftSide.topImage.btn.title}
            </Title>
          </MotionBox>
        </MotionBox>
        <MotionBox
          initial="default"
          variants={bottomImage}
          animate={rightSideAnimationController}
          sx={{ position: "absolute", left: "0", top: "0" }}
        >
          <GatsbyImageWithArtDirection
            sx={{
              width: "100%",
              height: [width, "auto", "auto", "auto"],
              maxHeight: ["500px","100%", "100%", "100%"],
            }}
            as={GatsbyImage}
            desktopImage={leftSide.bottomImage.image}
            mobileImage={leftSide.bottomImage.imagemobile}
            alt={leftSide.bottomImage.alt}
          />
        </MotionBox>
      </Box>
    </Box>
  );
};

export default React.memo(SlidingImages);
