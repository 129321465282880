import React from "react";
import { get } from "../../constants/theme";
import { SpecificationsQuery } from "../../types/pages/collection";
import { graphql, useStaticQuery } from "gatsby";
import Box from "../Box/Box";
import styled from "styled-components";
import { SxProp } from "../../constants/sx";
import css from "@styled-system/css";
import { Paragraph, Title } from "../Styled/Text";
import { clampBuilder } from "../../helpers/sizeCalc";

type Props = {
  listspec:{
    list: Array <{
      field: string;
      value: string;
    }>
    title: string;
    footer?: string;
  }
};

export const SpecFieldText = styled("span")<SxProp>`
  font-family: custom_42266;
  font-weight: 400;
  font-size: 12px;
  font-size: clamp(0.75rem, 0.4729rem + 0.7401vw, 1.25rem);
  line-height: 26px;
  text-transform: uppercase;
  text-align: center;
  color: ${get("palette.text.brown")};

  @media screen and (max-width: 599px) {
    font-size: 16px;
    line-height: 26px;
    font-size: clamp(1.125rem, 0.4082rem + 3.5842vw, 1.75rem);
  }

  ${(p) => p.sx && css({ ...p.sx })};
`;

const Specification = ({listspec}: Props) => {
  

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: get("palette.background.gold"),
        padding: [
          "clamp(7.1875rem, 0.0907rem + 35.4839vw, 13.375rem) 5%",
          "clamp(7.1875rem, 0.0907rem + 35.4839vw, 13.375rem) 5%",
          "clamp(6.625rem, 2.3999rem + 11.2858vw, 14.25rem) 12.5%",
          "clamp(6.625rem, 2.3999rem + 11.2858vw, 14.25rem) 12.5%",
        ],
        marginBottom: clampBuilder(599, 1680, 1.8125, 4.0625),
        marginTop: clampBuilder(599, 1680, 1.8125, 4.0625),
      }}
    >
      <Title
        variant="paragraph"
        sx={{
          textAlign: "center",
          alignSelf: "center",
          marginBottom: "clamp(3.875rem, -0.0677rem + 19.7133vw, 7.3125rem)",
        }}
      >
        {listspec.title}
      </Title>

      <Box
        sx={{
          alignSelf: ["center", "center", "center", "center"],
          width: ["100%", "80%", "80%", "80%"],
          "& > :nth-child(1n)": {
            marginBottom: [0, 0, "24px", "24px"],
            "::after": {
              display: ["block", "block", "none", "none"],

              content: `""`,
              width: "50px",
              height: "1px",
              maxHeight: "1px",
              opacity: 0.85,
              margin: "clamp(1.4375rem, -1.1328rem + 12.8516vw, 3.6785rem) 0",
              background: get("palette.text.dark_gold"),
            },
          },
          "& > :last-child": {
            marginBottom: 0,
            "::after": {
              display: "none",
            },
          },
        }}
      >
        {listspec.list.map((item) => (
          <Box
            key={item.field}
            sx={{
              display: "flex",
              flexDirection: ["column", "column", "row", "row"],
              alignItems: "center",
              justifyContent: "stretch",
              width: "100%",
              gap: [".5rem", ".5rem", 0, 0],
              whiteSpace: ["unset", "nowrap", "nowrap", "nowrap"],
            }}
          >
            <SpecFieldText
              sx={{
                fontWeight: [800, 400, 400, 400],
                color: [
                  get("palette.text.brown"),
                  get("palette.text.brown"),
                  get("palette.text.brown"),
                  get("palette.text.brown"),
                ],
              }}
            >
              {item.field}
            </SpecFieldText>
            <Box
              sx={{
                display: ["none", "none", "block", "block"],
                flex: 1,
                alignSelf: "flex-end",
                height: "1px",
                maxHeight: "1px",
                background: get("palette.text.dark_gold"),
                margin: "0 0.5rem",
              }}
            />
            <SpecFieldText
              sx={{
                color: [
                  get("palette.text.dark_gold"),
                  get("palette.text.brown"),
                  get("palette.text.brown"),
                  get("palette.text.brown"),
                ],
                justifySelf: "flex-end",
                whiteSpace: ["unset", "nowrap", "nowrap", "nowrap"],
              }}
            >
              {item.value}
            </SpecFieldText>
          </Box>
        ))}
              <Paragraph
        sx={{
          width: "100%",
          textAlign: "center",
        }}
       >
          {listspec.footer}
      </Paragraph>
      </Box>

    </Box>
  );
};

export default React.memo(Specification);
