const getObjectsCoordinates = (
  imgWidth: number,
  objectToImageRatio: number,
  objectsOffsetRation: number,
  objects: any[]
) => {
  const objectSize = imgWidth * objectToImageRatio;
  const objectOffsetSize = objectSize * objectsOffsetRation;

  return Array(objects.length)
    .fill({ start: 0, end: 0 })
    .map((v, i) => ({
      start: objectSize * i - objectOffsetSize * i,
      end: objectSize * i + objectSize - objectOffsetSize * i,
      object: {
        ...objects[i],
      },
    }));
};

export default getObjectsCoordinates;
