import { graphql, useStaticQuery } from "gatsby";
import React, { MouseEvent } from "react";
import { isMobileOnly } from "react-device-detect";
import { get } from "../../constants/theme";
import useDebounce from "../../hooks/useDebounce";
import useRingsHoverPicker from "../../hooks/useRingsHoverPicker";
import useWindowSize from "../../hooks/useWindowSize";
import { CollectionRingsPickerQuery } from "../../types/pages/collection";
import Box from "../Box/Box";
import MotionBox from "../Box/MotionBox";

const RingPicker = () => {
  const rings = useStaticQuery<CollectionRingsPickerQuery>(graphql`
    query CollectionRingsPickerQuery {
      translationsJson {
        pages {
          collection {
            ringsCollection {
              rings {
                text
              }
            }
          }
        }
      }
    }
  `).translationsJson.pages.collection.ringsCollection.rings;
  const [imgSize, setImgSize] = React.useState<number>(0);

  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const [position, setPosition] = React.useState({
    prev: 0,
    current: 0,
  });
  const mousePosition = useDebounce(position, 50);

  const { width, height } = useWindowSize();

  const imageRef = React.useCallback((node: HTMLImageElement | null) => {
    if (node !== null) {
      setImgSize(node.width);
    }
  }, []);

  const handleMouseOver = React.useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      setIsHovered(true);
      setPosition(() => ({
        prev: mousePosition.current,
        current: e.clientX,
      }));
    },
    [mousePosition]
  );

  const handleMouseLeave = React.useCallback(() => {
    setIsHovered(false);
  }, []);

  const activeRing = useRingsHoverPicker(
    imgSize,
    rings,
    mousePosition.current + (imgSize - width) / 2,
    position.current < position.prev,
    isHovered
  );

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: [height / 2, height / 2, height / 2, height],
        width: "100vw",
        padding: [
          "clamp(7.1875rem, 0.0907rem + 35.4839vw, 13.375rem) 5%",
          "clamp(7.1875rem, 0.0907rem + 35.4839vw, 13.375rem) 5%",
          "clamp(6.625rem, 2.3999rem + 11.2858vw, 14.25rem) 5%",
          "clamp(6.625rem, 2.3999rem + 11.2858vw, 14.25rem) 5%",
        ],
      }}
    >
      {activeRing && !isMobileOnly && (
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          sx={{
            zIndex: 2,
            display:
              activeRing.start - (imgSize - width) / 2 < 0 ||
              activeRing.end - (imgSize - width) / 2 > width
                ? "none"
                : "block",
            position: "absolute",
            top: "0%",
            fontFamily: "custom_42262",
            fontWeight: 400,
            fontFeatureSettings:
              '"liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0',
            textRendering: "optimizeLegibility",
            fontSmooth: "antialiased",
            left: activeRing.start - (imgSize - width) / 2,
            width: activeRing.end - activeRing.start,
            color: get("palette.text.dark_gold"),
          }}
        >
          {activeRing.object.text}
        </MotionBox>
      )}
      <Box
        onMouseOver={handleMouseOver}
        onMouseMove={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        ref={imageRef}
        sx={{
          position: "absolute",
          top: 0,
          left: "50%",
          width: ["300%", "150%", "150%", "150%"],
          height: "100%",
          objectFit: "contain",
          transform: "translateX(-50%)",
        }}
        as="img"
        src="/ringwhite.jpg"
        alt=""
      />
    </Box>
  );
};

export default React.memo(RingPicker);
