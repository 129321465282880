import React from "react";
import getObjectsCoordinates from "../helpers/imageObjectsSpotter";

function useRingsHoverPicker(
  imageWidth: number,
  rings: Array<{ text: string }>,
  cursorX: number,
  isLeftDirection: boolean,
  isHovered: boolean
) {
  const [activeRing, setActiveRing] = React.useState<
    | {
        start: number;
        end: number;
        object: {
          [key: string]: any;
        };
      }
    | undefined
  >(undefined);
  const [ringsCoordinates, setRingsCoordinates] = React.useState<
    Array<{
      start: number;
      end: number;
      object: {
        [key: string]: any;
      };
    }>
  >([]);

  React.useEffect(() => {
    setRingsCoordinates(
      getObjectsCoordinates(imageWidth, 0.1323, 0.3408072, rings)
    );
  }, [imageWidth, rings]);

  React.useEffect(() => {
    if (!isHovered) {
      setActiveRing(undefined);
      return;
    }

    const ringInInterval = ringsCoordinates.filter((r) => {
      if (r.start <= cursorX && r.end >= cursorX) {
        return true;
      } else {
        return false;
      }
    });

    try {
      if (ringInInterval.length === 0) {
        setActiveRing(undefined);
        return;
      }

      if (ringInInterval.length === 1) {
        setActiveRing(ringInInterval[0]);
        return;
      }

      if (ringInInterval.length > 1) {
        const left =
          ringInInterval[0].start < ringInInterval[1].start
            ? ringInInterval[0]
            : ringInInterval[1];
        const right =
          ringInInterval[1].start > ringInInterval[0].start
            ? ringInInterval[1]
            : ringInInterval[0];

        if (Math.abs(left.end - cursorX) > Math.abs(right.start - cursorX)) {
          setActiveRing(ringInInterval[0]);
        } else {
          setActiveRing(ringInInterval[1]);
        }
      }
    } catch (error) {
      setActiveRing(undefined);
    }
  }, [cursorX, isLeftDirection, isHovered, ringsCoordinates]);

  return activeRing;
}

export default useRingsHoverPicker;
